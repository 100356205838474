<template>
    <Sidebar
      :open="ui.displaySidebar"
      :variant="['FILTER_VIEW', 'MAIN_MENU_VIEW', 'TERMS', 'PRIVACY'].includes(ui.sidebarView) ? 'left' : 'right'"
    >
      <Page v-if="ui.sidebarView === 'TERMS'" subtype="conditions" class="bg-white" :show-close="true" />
      <Page v-if="ui.sidebarView === 'PRIVACY'" subtype="gdpr" class="bg-white" :show-close="true" />
      <CartSidebarView v-if="ui.sidebarView === 'CART_VIEW'" />
      <MobileCategoryMenu v-show="ui.sidebarView === 'FILTER_VIEW'" />
      <MobileMainMenu v-if="ui.sidebarView === 'MAIN_MENU_VIEW'" />
      <MobileAuthorizedMenu v-if="ui.sidebarView === 'AUTH_MENU_VIEW'" />
    </Sidebar>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { ROUTES } from "@utils/routes";
import { useUIStore } from '@stores/ui';
import { useSelectedStore } from '@stores/selected';
import { siteSettings } from "@settings/site.settings";
import Sidebar from "@components/common/sidebar/sidebar";
import CartSidebarView from "@components/cart/cart-sidebar-view";
import MobileCategoryMenu from "@components/layout/mobile-menu/mobile-category-menu";
import MobileMainMenu from "@components/layout/mobile-menu/mobile-main-menu";
import MobileAuthorizedMenu from "@components/layout/mobile-menu/mobile-authorized-menu";
import Page from "@components/content/page";

const gtm = useGtm();
const open = useState('sidebar.open', () => false);

const ui = useUIStore();
const { $eventBus } = useNuxtApp();
const store = useSelectedStore();
const routeLang = useRouteLang();

const pushLink = (type) => {
  const lang = routeLang.code.value;
  const section = lang === siteSettings.language.code ? ROUTES : (ROUTES[lang] || ROUTES);
  const prefix = lang === siteSettings.language.code ? '' : `/${lang}`;
  let url = '';
  let name = `${type} [Sidebar]`;
  if (type === 'TERMS') {
    url = `${prefix}${section['TERMS']}`;
  } else if (type === 'PRIVACY') {
    url = `${prefix}${section['PRIVACY']}`;
  } else if (type === 'CART_VIEW') {  
    url = `${prefix}${section['CHECKOUT']}`;
  } else if (type === 'FILTER_VIEW') {  
    url = `${prefix}${section['ADS']}#filters`;
  } else if (type === 'MAIN_MENU_VIEW') {  
    url = `#menu`;
  } else if (type === 'AUTH_MENU_VIEW') {  
    url = `#menu`;
  }
  if (process.client && url) {
    if (!ui.displayModal && !ui.displaySidebar) {
      store.resetStack();
    }
    store.addToStack(url);
    window.history.pushState({}, '', url);
    try {
      gtm.trackView(name, url);
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

const pullLink = () => {
  if (store.stack?.length) {
    store.removeFromStack();
  }
  if (store.stack?.length) {
    window.history.replaceState({}, '', store.lastInStack() || translatePath(ROUTES.HOME));
  } else {
    window.history.replaceState({}, '', translatePath(ROUTES.HOME));
  }
}

const openSidebar = (value) => {
  ui.setSidebarView(value);
  ui.setDisplaySidebar(open.value = true);
  try {
    gtm.trackEvent({
      event: `sidebar.open.${value}`,
      category: 'sidebar',
      action: 'open',
      label: `Opened sidebar window: ${value}`,
      value: 0,
      noninteraction: false,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}

const closeSidebar = () => { 
  try {
    gtm.trackEvent({
      event: `sidebar.close.${ui.sidebarView}`,
      category: 'sidebar',
      action: 'close',
      label: `Closed sidebar window: ${ui.sidebarView}`,
      value: 0,
      noninteraction: false,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
  ui.setDisplaySidebar(open.value = false);
  ui.setSidebarView('');
};

$eventBus.on('sidebar:open', (value) => {
  pushLink(value);
  openSidebar(value);
});

$eventBus.on('sidebar:close', () => {
  if (open.value) {
    closeSidebar();
    pullLink();
  }
});

onMounted(() => {
  if (process.client) {
    window.addEventListener('popstate', (event) => {
      if (open.value) {
        closeSidebar();
      }
    });
  }
});

onUnmounted(() => {
  $eventBus.off('sidebar:open');
  $eventBus.off('sidebar:close');
});

</script>
