
export const API_ENDPOINTS: Record<string, string> = {
  APP: "/api/v1/app",
  CONFIG: "/api/v1/config",
  CONTACT_FORM: "/api/v1/contact-form",
  CONTENT_CATEGORY: "/api/v1/content/category",
  CONTENT_POST_MANAGE_ID: "/api/v1/content/post/manage/{id}",
  CONTENT_POST_MANAGE: "/api/v1/content/post/manage",
  CONTENT_POST_ID_STATUS: "/api/v1/content/post/{id}/status",
  CONTENT_POST: "/api/v1/content/post",
  CONTENT_POST_ID: "/api/v1/content/post/{id}",
  CONTENT_POST_ID_FAVORITE: "/api/v1/content/post/{idPost}/favorite",
  CONTENT_POST_ID_IMAGE_ID: "/api/v1/content/post/{idPost}/image/{id}",
  CONTENT_POST_ID_IMAGE: "/api/v1/content/post/{idPost}/image",
  CONTENT_POST_ID_LIKE: "/api/v1/content/post/{idPost}/like",
  CONTENT_POST_ID_MESSAGE: "/api/v1/content/post/{idPost}/message",
  BANNER: "/api/v1/banner",
  COUNTRY: "/api/v1/country",
  CURRENCY: "/api/v1/currency",
  ESHOP_AVAILABILITY: "/api/v1/eshop/availability",
  ESHOP_CHECKOUT_CART_ABANDONMENT_REASON: "/api/v1/eshop/checkout/cart/abandonment-reason",
  LANGUAGE: "/api/v1/language",
  CUSTOMER: "/api/v1/customer",
  CUSTOMER_ID: "/api/v1/customer/{id}",
  CUSTOMER_ACCOUNT_ACTIVATION_HASH: "/api/v1/customer/account-activation/{hash}",
  CUSTOMER_ACCOUNT_ACTIVATION: "/api/v1/customer/account-activation",
  CUSTOMER_ACCOUNT_DELETE_HASH: "/api/v1/customer/account-delete/{hash}",
  CUSTOMER_ACCOUNT_DELETE: "/api/v1/customer/account-delete",
  CUSTOMER_CHANGE_PASSWORD: "/api/v1/customer/change-password",
  CUSTOMER_FOLLOWINGS_ID: "/api/v1/customer/followings/{id}",
  CUSTOMER_FOLLOWINGS: "/api/v1/customer/followings",
  CUSTOMER_FORGOT_PASSWORD: "/api/v1/customer/forgot-password",
  CUSTOMER_FORGOT_PASSWORD_HASH: "/api/v1/customer/forgot-password/{hash}",
  CUSTOMER_SETTING_NOTIFICATION_EMAIL: "/api/v1/customer/setting/notification/email",
  HEARTBEAT: "/api/v1/heartbeat",
  SIGN_HASH: "/api/v1/sign/hash",
  SIGN: "/api/v1/sign",
  ESHOP_CATEGORY: "/api/v1/eshop/category",
  ESHOP_CHECKOUT_CART: "/api/v1/eshop/checkout/cart",
  ESHOP_CHECKOUT_CART_ID: "/api/v1/eshop/checkout/cart/{id}",
  ESHOP_CHECKOUT_CART_MESSAGE: "/api/v1/eshop/checkout/cart/{idCart}/message",
  ESHOP_CHECKOUT_CART_ADDRESS: "/api/v1/eshop/checkout/{idCart}/address",
  ESHOP_CHECKOUT_CART_DELIVERY: "/api/v1/eshop/checkout/{idCart}/delivery",
  ESHOP_CHECKOUT_CART_PAYMENT: "/api/v1/eshop/checkout/{idCart}/payment",
  ESHOP_CHECKOUT_CART_ORDER: "/api/v1/eshop/checkout/{idCart}/order",
  ESHOP_ORDER: "/api/v1/eshop/order",
  ESHOP_ORDER_ID_STATUS: "/api/v1/eshop/order/{idOrder}/status",
  ESHOP_ORDER_ID_RATING: "/api/v1/eshop/order/{idOrder}/rating",
  ESHOP_PARAMETER: "/api/v1/eshop/parameter",
  ESHOP_PRODUCT_VARIANT: "/api/v1/eshop/product/variant",
  ESHOP_PRODUCT_VARIANT_ID: "/api/v1/eshop/product/variant/{id}",
  ESHOP_PRODUCT_VARIANT_ID_FAVORITE: "/api/v1/eshop/product/variant/{idVariant}/favorite",
  ESHOP_PRODUCT_VARIANT_ID_LIKE: "/api/v1/eshop/product/variant/{idVariant}/like",
  ESHOP_PRODUCT_VARIANT_ID_MESSAGE: "/api/v1/eshop/product/variant/{idVariant}/message",
  ESHOP_PRODUCT_ID_DUPLICATE: "/api/v1/eshop/product/{id}/duplicate",
  ESHOP_PRODUCT_ID_STATUS: "/api/v1/eshop/product/{id}/status",
  ESHOP_PRODUCT_ID: "/api/v1/eshop/product/{id}",
  ESHOP_PRODUCT: "/api/v1/eshop/product",
  ESHOP_PRODUCT_ID_VARIANT_ID_IMAGE_ID: "/api/v1/eshop/product/{idProduct}/variant/{idVariant}/image/{id}",
  ESHOP_PRODUCT_ID_VARIANT_ID_IMAGE: "/api/v1/eshop/product/{idProduct}/variant/{idVariant}/image",
  SERVICE_GEOCODE_COORDINATES: "/api/v1/service/geocode/coordinates",
  SERVICE_TRANSLATION_LANG: "/api/v1/service/translation/{lang}",
  SUPPLIER_BLOCKED_ID: "/api/v1/supplier/blocked/{id}",
  SUPPLIER_BLOCKED: "/api/v1/supplier/blocked",
  SUPPLIER_FOLLOWERS: "/api/v1/supplier/followers",
  SUPPLIER: "/api/v1/supplier",
  SUPPLIER_ID: "/api/v1/supplier/{id}",
  SUPPLIER_ID_IMAGE: "/api/v1/supplier/{idSupplier}/image",
  SUPPLIER_ID_IMAGE_ID: "/api/v1/supplier/{idSupplier}/image/{id}",
  SUPPLIER_ID_RATING: "/api/v1/supplier/{idSupplier}/rating",
  VISITOR: "/api/v1/visitor",
  RUIAN: "/api/fe/ruian"
};
