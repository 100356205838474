<template>
    <Popover class="absolute top-3 end-3 text-body">
      <PopoverButton>
        <span :title="$t('text-insert-my-info')">
          <DocumentText class="w-6 h-6" />
        </span>
      </PopoverButton>
      <Transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel v-slot="{ close }" class="absolute -right-2 transform bottom-10 mb-2 py-2 w-60 bg-light rounded shadow-700 z-20">
          <ul>
            <li>
              <button
                v-bind="getRootProps()"
                class="block w-full py-1 px-4 text-sm text-start font-semibold text-primary-dark transition duration-200 hover:text-accent-hover hover:underline focus:outline-none"
              >
                <input v-bind="getInputProps()" name="images" />
                <div class="flex items-center justify-start space-x-2" @click="() => closeFunc = close">
                  <ImageSolid class="w-4 h-4" />
                  <div>{{ $t('text-insert-image') }}</div>
                </div>
              </button>
            </li>
            <li v-if="auth.idSupplier">
              <button
                @click="insertBillingAddress(close)"
                class="block w-full py-1 px-4 text-sm text-start font-semibold text-primary-dark transition duration-200 hover:text-accent-hover hover:underline focus:outline-none"
              >
                <div class="flex items-center justify-start space-x-2">
                  <IdentificationSolid class="w-4 h-4" />
                  <div>{{ $t('text-insert-billing-address') }}</div>
                </div>
              </button>
            </li>
            <li v-if="auth.idSupplier">
              <button
                @click="insertDeliveryAddress(close)"
                class="block w-full py-1 px-4 text-sm text-start font-semibold text-primary-dark transition duration-200 hover:text-accent-hover hover:underline focus:outline-none"
              >
                <div class="flex items-center justify-start space-x-2">
                  <IdentificationIcon class="w-4 h-4" />
                  <div>{{ $t('text-insert-delivery-address') }}</div>
                </div>
              </button>
            </li>
            <li v-if="auth.idCustomer">
              <button
                @click="insertEmailAddress(close)"
                class="block w-full py-1 px-4 text-sm text-start font-semibold text-primary-dark transition duration-200 hover:text-accent-hover hover:underline focus:outline-none"
              >
                <div class="flex items-center justify-start space-x-2">
                  <AtIcon class="w-4 h-4" />
                  <div>{{ $t('text-insert-email-address') }}</div>
                </div>
              </button>
            </li>
            <li v-if="auth.idSupplier">
              <button
                @click="insertPhoneNumber(close)"
                class="block w-full py-1 px-4 text-sm text-start font-semibold text-primary-dark transition duration-200 hover:text-accent-hover hover:underline focus:outline-none"
              >
                <div class="flex items-center justify-start space-x-2">
                  <PhoneIcon class="w-4 h-4" />
                  <div>{{ $t('text-insert-phone-number') }}</div>
                </div>
              </button>
            </li>
          </ul>
        </PopoverPanel>
      </Transition>
    </Popover>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { useDropzone } from "vue3-dropzone";
import { useAuthStore } from '@stores/auth';
import { useCustomerStore } from '@stores/customer';
import DocumentText from "@components/icons/outline/document-text";
import ImageSolid from "@components/icons/solid/image-solid";
import IdentificationIcon from "@components/icons/outline/identification-icon";
import IdentificationSolid from "@components/icons/solid/identification-solid";
import AtIcon from "@components/icons/outline/at-icon";
import PhoneIcon from "@components/icons/outline/phone-icon";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  images: {
    type: Array,
    required: true
  },
  onChange: {
    type: Function,
    default: null
  }
});

const { t } = useI18n();
const { $eventBus } = useNuxtApp();
const auth = useAuthStore();
const customer = useCustomerStore();
const closeFunc = ref(() => {});

const getAdress = (address) => {
  if (!address) return '';

  const fullStreet = ((address?.street ? address?.street : '') + ' ' + (address?.streetNo ? address?.streetNo : '') + (address?.houseNo ? `/${address?.houseNo}` : '')).trim();
  const fullName = _.join(_.filter([
    address.namePrefix,
    address.firstName,
    address.lastName,
    address.nameSuffix
  ]), ' ').trim();
  return _.join(_.filter([
    address?.company !== fullName ? address?.company : null,
    address?.companyPerson ? address?.companyPerson : fullName,
    fullStreet,
    _.join(_.filter([address?.zip, address?.city]), ' '),
    address?.country?.name,
  ]), ", ");
}

const insertBillingAddress = (close) => {
  const address = customer.supplier?.billingAddress;
  const result = _.join(_.filter([
    getAdress(address),
    address?.companyNo ? `${t('text-companyNo')}: ${address?.companyNo}` : null,
    address?.companyVatNo ? `${t('text-companyVatNo')}: ${address?.companyVatNo}` : null,
  ]), ", ");
  emit('update:modelValue', `${props.modelValue} ${result} `);
  close();
  $eventBus.emit('focus:input', 'comment');
}

const insertDeliveryAddress = (close) => {
  emit('update:modelValue', `${props.modelValue} ${getAdress(customer.supplier?.deliveryAddress)} `);
  close();
  $eventBus.emit('focus:input', 'comment');
}

const insertEmailAddress = (close) => {
  emit('update:modelValue', `${props.modelValue} ${customer.supplier?.billingAddress?.email || customer.supplier?.deliveryAddress?.email || customer.email || ''} `);
  close();
  $eventBus.emit('focus:input', 'comment');
}

const insertPhoneNumber = (close) => {
  const callingCode = customer.supplier?.billingAddress?.calling?.callingCode || customer.supplier?.deliveryAddress?.calling?.callingCode || '';
  emit('update:modelValue', `${props.modelValue} ${callingCode ? '+' + callingCode + ' ' : ''}${customer.supplier?.billingAddress?.phone || customer.supplier?.deliveryAddress?.phone || ''} `);
  close();
  $eventBus.emit('focus:input', 'comment');
}

const files = ref(props.images);
const setFiles = async (data) => {
  _.map(data, (file) => {
    encodeFileContent(file);
    file.url = URL.createObjectURL(file);
    return file;
  });
  files.value = data;
}

watch(() => props.images, (value) => {
  if (_.isArray(value) && _.get(value, 'length', null) === 0) {
    files.value = [];
  }
});

const onDrop = (acceptedFiles, rejectedReasons) => {
  if (props.onChange) {
    props.onChange(acceptedFiles);
  }
  try {
    setFiles(acceptedFiles);
  } catch (error) {
    Sentry.captureException(error);
  }
  props.images.push(...acceptedFiles);
  if (closeFunc.value) {
    closeFunc.value();
  }
}

const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop, accept: 'image/*' });

</script>
