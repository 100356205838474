<template>
  <div v-if="value" class="group">
    <div v-if="format === 'html'" ref="htmlRef" :class="[ 'prose max-w-none', expanded ? '' : clampClass, className ]" v-html="textToHtml(value)" />
    <div v-else ref="plainRef" :class="[ 'prose max-w-none', expanded ? '' : clampClass, className ]">
      {{ value }}
    </div>
    <template v-if="clamped && !expanded">
      <a
        href="#"
        @click="toggleLines"
        :class="[ className, 'font-bold !text-primary-dark group-hover:text-primary-dark-hover focus:text-primary-dark-hover group-hover:underline' ]"
      >
        {{ more ? more : $t('common:text-read-more') }}
      </a>
    </template>
    <template v-if="clamped && expanded">
      <a
        href="#"
        @click="toggleLines"
        :class="[ className, 'font-bold !text-primary-dark group-hover:text-primary-dark-hover focus:text-primary-dark-hover group-hover:underline' ]"
      >
        {{ less ? less : $t('common:text-less') }}
      </a>
    </template>
  </div>
</template>

<script setup>
import _truncate from 'lodash-es/truncate';

const props = defineProps({
    value: {
        type: String,
        default: ''
    },
    lines: {
        type: Number,
        default: 7
    },
    more: {
        type: String,
        default: ''
    },
    less: {
        type: String,
        default: ''
    },
    className: {
        type: String,
        default: ''
    },
    format: {
        type: String,
        default: 'plain'
    }
})

const expanded = ref(false);
const htmlRef = ref(null);
const plainRef = ref(null);

const clampClass = computed(() => ({
  0: 'line-clamp-none',
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
  5: 'line-clamp-5',
  6: 'line-clamp-6',
  7: 'line-clamp-7',
}[props.lines] || 'line-clamp-7'));

const clamped = computed(() => process.client && (props.format === 'html' ? (htmlRef.value?.clientHeight < htmlRef.value?.scrollHeight) : (plainRef.value?.clientHeight < plainRef.value?.scrollHeight)));

const toggleLines = (event) => {
    event.preventDefault();
    expanded.value = !expanded.value;
};

</script>
