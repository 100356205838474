<template>
  <div class="pr-0 md:pr-5">
    <div ref="observedElement" class="w-full">
      <div class="w-full flex flex-row items-center justify-between space-x-2 mb-4 md:mb-8">
        <div class="w-full flex flex-wrap">
          <FilterTagCategory :data="variant" class="my-0.5" />
          <FilterTagParameters :data="variant" class-name="my-0.5" />
        </div>
        <VariantFavorite :variant="variant" class="flex-shrink-0" />
      </div>

      <h1 class="font-bold text-2xl tracking-tight text-primary-dark cursor-pointer transition-colors hover:text-primary-dark-hover no-underline hover:underline" :title="variant.name" @click="navigate(`${ROUTES.PRODUCT}/${slugify(variant.id, variant.name)}`)">
        {{ variant.name }}
      </h1>

      <div class="w-full my-2">
        <div class="flex flex-row items-center justify-between">
          <span v-if="_.get(variant, 'price', null) === null" class="flex items-center">
            <ins class="text-base font-bold text-primary-dark no-underline">
              {{ $t('text-price-negotiable') }}
            </ins>
          </span>
          <span v-else class="flex items-center">
            <ins class="text-base font-bold text-primary-dark no-underline">
              {{ basePrice ? basePrice : price }}
            </ins>
            <del v-if="discount" class="text-sm md:text-base font-normal text-muted ms-2">
              {{ price }}
            </del>
          </span>
          <VariantShare :variant="variant" />
        </div>
      </div>

      <Truncate :lines="4" :value="localized(variant, 'content')" format="html" />

      <div v-if="store.action !== 'HIDE_ADD_TO_CART' && isOrderable && variant?.product?.type !== PRODUCT_TYPE.WANTED" class="w-full">
        <div class="mt-4 flex flex-col">
          <span v-if="variant.stockCount" class="text-base text-body whitespace-nowrap">
            {{ isOrderable
              ? (variant.stockCount ? formatString(variant.stockCount, $t(variant?.product?.type === PRODUCT_TYPE.OFFER ? 'text-pieces-available' : 'text-pieces-required')) : '')
              : $t('text-out-stock')
            }}
          </span>
        </div>
      </div>
      
      <div class="w-full my-8">
        <div class="w-full flex flex-col">
          <div v-if="store.action !== 'HIDE_ADD_TO_CART'" class="mb-3 lg:mb-0 w-full">
            <EditProductBtn 
              v-if="_.get(variant, 'product.supplier.id') === auth?.idSupplier" 
              :data="variant.product" 
              variant="big"
              class="!w-full"
            />
            <AddToCart 
              v-else
              :data="variant" 
              variant="simple" 
              :disabled="!isOrderable"
              class="!w-full"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-row items-center justify-between mb-9">
      <div class="flex flex-row items-center justify-starts space-x-2.5">
        <VariantViews :variant="variant" />
        <VariantLikes :variant="variant" />
        <MessagesBadge :variant="variant" :on-click="() => $emit('select:tab:comments', props.variant.id)" />
      </div>
      <ReportVariant :variant="variant" />
    </div>

    <VariantParameters :variant="variant" class="mb-9" />

    <VerifiedUser :data="variant?.product" :type="CART_TYPE.SELLER" class="mb-9" />

    <div class="w-full flex flex-row items-center justify-between">
      <h2 class="text-base text-primary-dark font-bold pb-3">
        {{ $t(variant?.product?.type === PRODUCT_TYPE.OFFER ? 'text-supplier' : 'text-requester') }}
      </h2>
      <Link 
        :href="translatePath(`${ROUTES.SUPPLIER}/${slugify(variant?.product?.supplier?.id, variant?.product?.supplier?.name)}`)"
        class="whitespace-nowrap text-xs underline hover:no-underline pb-3"
        @click="closeModal"
      >
        {{ $t('text-show-profile') }}
      </Link>
    </div>
    <ProfileHeader :data="variant?.product" :type="CART_TYPE.SELLER" :show="{ rating: true }" class="mb-9" />

    <VariantMessages :variant="variant" />
  </div>    
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { PRODUCT_TYPE, CART_TYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { formatString } from "@utils/format-string";
import usePrice from "@utils/use-price";
import AddToCart from "@components/product/add-to-cart/add-to-cart";
import EditProductBtn from "@components/product/product-edit/edit-product-btn";
import ProfileHeader from "@components/profile/profile-header";
import VariantFavorite from "@components/product/product-details/variant-favorite";
import VariantViews from "@components/product/product-details/variant-views";
import VariantLikes from "@components/product/product-details/variant-likes";
import MessagesBadge from "@components/common/messages-badge";
import FilterTagCategory from "@components/filter/filter-tag-category";
import FilterTagParameters from "@components/filter/filter-tag-parameters";
import Scrollbar from "@components/ui/scrollbar";
import Truncate from "@components/ui/truncate";
import VariantParameters from "@components/product/product-details/variant-parameters";
import VerifiedUser from "@components/profile/verified-user";
import Link from "@components/ui/link/link";
import VariantMessages from "@components/product/product-details/variant-messages";
import ReportVariant from "@components/product/product-details/report-variant";
import VariantShare from "@components/product/product-details/variant-share";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: 'page' //page, modal
  }
})

const { $eventBus } = useNuxtApp();
const ui = useUIStore();
const auth = useAuthStore();
const store = useSelectedStore();
const observedElement = ref(null);
const waypoint = useWaypoint(observedElement, props.size);

const emit = defineEmits(['select:tab:comments']);

const { price, basePrice, discount } = usePrice({
  amount: +props.variant?.price,
  baseAmount: +props.variant?.sale_price,
  currencyCode: props.variant?.currency?.code
});

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async (path) => {
    closeModal();
    await navigateTo(translatePath(path));
}

const isOrderable = computed(() => {
  return _.get(props.variant, 'stockCount', 0) !== 0 && _.get(props.variant, 'availability.isProductOrderable', true);
});

</script>
