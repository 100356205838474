<template>
  <div>
    <ContentDetailGallery :variant="variant" class="float-none md:float-left mr-0 md:mr-5" />
    <div class="flex flex-row items-start justify-between space-x-2 mb-4 md:mb-8 pt-5 pl-5 md:pl-0 pr-5 md:pr-10">
      <h1 class="font-bold text-2xl tracking-tight text-primary-dark cursor-pointer transition-colors hover:text-primary-dark-hover no-underline hover:underline" :title="variant.name" @click="navigate(`${rootPath}/${slugify(variant.id, variant.name)}`)">
          {{ variant.name }}
      </h1>
      <NewsFavorite :variant="variant" class="flex-shrink-0" />
    </div>

    <ProfileHeader :data="variant" class="mb-9 ml-5 md:ml-0" />

    <div class="text-sml text-body font-normal mb-9 pl-5 md:pl-10 pr-5 md:pr-10">
      {{ localized(variant, 'perex') }}
    </div>

    <div class="prose max-w-none mb-9 pl-5 md:pl-10 pr-5 md:pr-10" v-html="localized(variant, 'content')" />

    <div class="w-full flex flex-row items-center justify-between mb-9 pl-5 md:pl-10">
      <div class="flex flex-row items-center justify-start space-x-2.5">
        <ContentViews :variant="variant" />
        <ContentLikes :variant="variant" />
      </div>
    </div>

  </div>    
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { CONTENT_TYPE } from "@utils/constants";
import { useUIStore } from '@stores/ui';
import { useAuthStore } from '@stores/auth';
import ProfileHeader from "@components/profile/profile-header";
import Truncate from "@components/ui/truncate";
import NewsFavorite from "@components/news/news-details/news-favorite";
import ContentViews from "@components/content/content-details/content-views";
import ContentLikes from "@components/content/content-details/content-likes";
import ContentDetailGallery from "@components/content/content-details/content-detail-gallery";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: 'page' //page, modal
  }
})

const { $eventBus } = useNuxtApp();
const ui = useUIStore();
const auth = useAuthStore();

const rootPath = computed(() => {
  return {
    inspiration: ROUTES.INSPIRATION,
    news: ROUTES.NEWS
  }[props.variant?.type] || ROUTES.PRODUCT;
});

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async (path) => {
    closeModal();
    await navigateTo(translatePath(path));
}

</script>
