<template>
  <div class="w-full">
    <FilterParameter 
      v-for="parameter in categoryParameters" 
      :key="parameter.idParameter" 
      :parameter="parameter" 
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useEnumStore } from '@stores/enum';
import { siteSettings } from "@settings/site.settings";
import FilterParameter from "@components/filter/filter-parameter";

const enums = useEnumStore();
const route = useRoute();
const routeLang = useRouteLang();
const parameters = ref([]);

const routePath = computed(() => route?.path || '');

const getCategoryId = (value) => {
  const lang = routeLang.code.value;
  const section = lang === siteSettings.language.code ? ROUTES : (ROUTES[lang] || ROUTES);
  const prefix = lang === siteSettings.language.code ? '' : `/${lang}`;

  return routePath.value.startsWith(`${prefix}${section['CATEGORY']}`) && value ? parseInt(value) : null
}

const idCategory = computed(() => getCategoryId(route.params.slug) || +route?.query?.idCategory || null);

const selectedCategories = computed(() => _.chain(idCategory.value || '').split(',').map(value => parseInt(value)).filter().value());

const categoryParameters = computed(() => _.filter(parameters.value, item => _.some(item.idCategories, cat => _.includes(selectedCategories.value, cat))));

const reload = (items) => {
  parameters.value = setupParameters(items);
}

onMounted(() => reload(enums.parameters));

watch(() => enums.parameters, (items) => reload(items));

watch(() => route.query, (query) => {
  _.forEach(parameters.value, parameter => {
    const queryKey = `p[${parameter.idParameter}]`;
    const values = _.get(query, queryKey, null);
    const checked = _.chain(values).split(',').filter().map(v => _.isString(v) && !isNaN(v) ? parseInt(v) : v).value();
    _.forEach(parameter.values, value => {
      value.value = _.includes(checked, value.id);
    });
  });
});

</script>
