<template>
  <button
    @click="onClick"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-accent-dark rounded-full transition-colors bg-primary-dark hover:text-primary-dark hover:bg-accent-dark-hover focus:bg-accent-dark-hover"
  >
    {{ $t('text-view') }}
  </button>
</template>

<script setup>
import { ROUTES } from "@utils/routes";

const props = defineProps({
  data: {
    type: Object,
    required: true 
  }
});

const { $eventBus } = useNuxtApp();

const onClick = async () => { 
  $eventBus.emit('modal:close');
  await navigateTo(translatePath(`${ROUTES.PRODUCT}/${slugify(props.data.id, props.data.name)}`));
};

</script>
