<template>
  <div :style="wrapperStyle">
    <div v-if="sizerStyle" :style="sizerStyle">
      <img 
        v-if="sizerSvg"
        alt=""
        :width="width || '100%'" 
        :height="height || '100%'" 
        aria-hidden="true" 
        role="presentation" 
        :src="`data:image/svg+xml;base64,${sizerSvg}`" 
        style="max-width: 100%; display: block; margin: 0px; border: none; padding: 0px;"
        loading="lazy"
      />
    </div>
    <img
      v-if="_.endsWith(defaultImage, '.svg')"
      :alt="alt"
      :sizes="width ? '' : '100vw'"
      :src="defaultImage"
      decoding="async"
      :style="imgStyle"
      :class="className"
      loading="lazy"
      @click="onClick"
    />
    <NuxtPicture 
      v-else
      :src="src" 
      format="webp"
      :alt="alt" 
      :width="width" 
      :height="height" 
      :sizes="sizes" 
      :quality="quality" 
      :fit="objectFit" 
      loading="lazy" 
      :imgAttrs="{ style: imgStyle, class: className }"
      :modifiers="{ rotate: null }"
      @click="onClick"
    />
  </div>
  
</template>

<script setup>
import _ from 'lodash-es';

const props = defineProps({
  src: {
    type: String,
    required: true 
  },
  alt: {
    type: String,
    required: false,
    default: () => ''
  },
  layout: {
    type: String,
    required: false,
    default: () => 'fill'
  },
  objectFit: {
    type: String,
    required: false,
    default: null
  },
  sizes: {
    type: Array,
    required: false,
    default: () => null //[640, 750, 828, 1080, 1200, 1920, 2048, 3840]
  },
  quality: {
    type: Number,
    required: false,
    default: () => 75
  },
  width: {
    type: Number,
    required: false,
    default: null
  },
  height: {
    type: Number,
    required: false,
    default: null
  },
  unoptimized: {
    type: Boolean,
    default: false
  },
  priority: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  className: {
    type: String,
    default: ''
  },
  objectPosition: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'empty'
  },
  onClick: {
    type: Function,
    default: () => { }
  }
});

const getInt = (x) => {
    if (typeof x === 'number') {
        return x;
    }
    if (typeof x === 'string') {
        return parseInt(x, 10);
    }
    return undefined;
}

const generateImageLink = (width) => {
  return `/_next-image?url=${encodeURIComponent(props.src)}&w=${width}&q=${props.quality}`;
}

const widthInt = getInt(props.width);
const heightInt = getInt(props.height);
const qualityInt = getInt(props.quality);

const srcSetString = (props.sizes || [])
  .map(width => `${generateImageLink(width)} ${width}w`)
  .join(', ');

const defaultImage = props.src; // widthInt ? generateImageLink(widthInt) : props.src; //TODO props.generateImageLink(3840);

let layout = props.sizes ? 'responsive' : 'intrinsic';

let wrapperStyle;
let sizerStyle;
let sizerSvg;
const imgStyle = {
  position:'absolute',
  top:0,
  left:0,
  bottom:0,
  right:0,
  boxSizing:'border-box',
  padding:0,
  border:'none',
  margin:'auto',
  display:'block',
  width:0,
  height:0,
  minWidth:'100%',
  maxWidth:'100%',
  minHeight:'100%',
  maxHeight:'100%',
  objectFit: props.objectFit,
  objectPosition: props.objectPosition
};

if (typeof widthInt !== 'undefined' && typeof heightInt !== 'undefined' && layout !== 'fill') { // <Image src="i.png" width="100" height="100" />
    const quotient = heightInt / widthInt;
    const paddingTop = isNaN(quotient) ? '100%' : `${quotient*100}%`;
    if (layout === 'responsive') { // <Image src="i.png" width="100" height="100" layout="responsive" />
        wrapperStyle = {
            display: 'block',
            overflow: 'hidden',
            position: 'relative',
            boxSizing: 'border-box',
            margin: 0
        };
        sizerStyle = {
            display: 'block',
            boxSizing: 'border-box',
            paddingTop
        };
    } else if (layout === 'intrinsic') { // <Image src="i.png" width="100" height="100" layout="intrinsic" />
        wrapperStyle = {
            display: 'inline-block',
            maxWidth: '100%',
            overflow: 'hidden',
            position: 'relative',
            boxSizing: 'border-box',
            margin: 0
        };
        sizerStyle = {
            boxSizing: 'border-box',
            display: 'block',
            maxWidth: '100%'
        };
        sizerSvg = btoa(`<svg width="${widthInt}" height="${heightInt}" xmlns="http://www.w3.org/2000/svg" version="1.1"/>`);
    } else if (layout === 'fixed') { // <Image src="i.png" width="100" height="100" layout="fixed" />
        wrapperStyle = {
            overflow: 'hidden',
            boxSizing: 'border-box',
            display: 'inline-block',
            position: 'relative',
            width: widthInt,
            height: heightInt
        };
    }
} else if (typeof widthInt === 'undefined' && typeof heightInt === 'undefined' && layout === 'fill') { // <Image src="i.png" layout="fill" />
    wrapperStyle = {
        display: 'block',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        boxSizing: 'border-box',
        margin: 0
    };
} 

let imgAttributes = {
    src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    srcSet: undefined,
    sizes: undefined
};
</script>
