import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { getDeviceInfo } from "@utils/get-device-info";
import { useAuthStore } from '@stores/auth';

export default async function (fcmToken = null) {
  const { ssrContext } = useNuxtApp();
  const auth = useAuthStore();

  if (fcmToken) {
    if (auth.fcmToken) {
      return auth.me;
    }
    auth.setFcmToken(fcmToken);
  }

  const userAgent = process.client ? navigator.userAgent : ssrContext.userAgent;
  const routeLang = useRouteLang();
  const body = Object.assign(
    {}, 
    { deviceInfo: getDeviceInfo(userAgent, routeLang.locale.value) },
    fcmToken ? { fcmToken } : {}
  );

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.HEARTBEAT, {
    method: 'POST',
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-me',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  } else {
    auth.setData(data.value);
  }

  return data.value;
}
