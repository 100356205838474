import _ from 'lodash-es';
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { useEnumStore } from '@stores/enum';

export default async function () {
  const config = useRuntimeConfig();
  const auth = useAuthStore();
  const store = useSelectedStore();
  const customer = useCustomerStore();
  const enums = useEnumStore();
  const route = useRoute();
  const switchLocalePath = useSwitchLocalePath();

  if (route.path !== switchLocalePath(customer?.language?.code || config.public.defaultLanguage)) {
    await navigateTo(switchLocalePath(customer?.language?.code || config.public.defaultLanguage));
    store.setLoadResetAll();
    await loadCategories(true);
    await loadParameters(true);
    await loadEshopAvailabilities(true);
    // await loadAbandonmentReasons(true);
    await loadBanners(true);

    await navigateTo(switchLocalePath(customer?.language?.code || config.public.defaultLanguage));
  }
}
