<template>
    <button
        :aria-pressed="active"
        :data-variant="variant"
        :class="classesName"
        :disabled="disabled"
        @click="onClick"
      >
          <slot />
          <span
            v-if="loading"
            :class="classes.loading"
            :style="{ borderTopColor: variant === 'outline' ? 'currentColor' : '#ffffff' }"
          />
    </button>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  className: {
    type: String,
    default: ''
  },
  variant: {
    type: String,
    default: 'normal' // "navbar" | "accent" | "normal" | "outline" | "custom" | "link"
  },
  size: {
    type: String,
    default: 'medium' // "big" | "medium" | "small" | "tiny"
  },
  active: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  onClick: {
    type: Function,
    default: () => { }
  }
});

const classes = {
  root: 'inline-flex items-center justify-center flex-shrink-0 font-bold leading-none rounded-full outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1',
  navbar: 'bg-primary-dark border border-solid border-accent-dark text-white text-base hover:bg-accent-dark-hover focus:bg-accent-dark-hover hover:text-primary-dark-hover focus:text-primary-dark-hover',
  accent: 'bg-accent-dark border border-accent-dark text-primary-dark text-base hover:bg-primary-dark-hover focus:bg-accent-dark-hover hover:text-accent-dark-hover focus:text-primary-dark-hover',
  normal: 'text-accent-dark border border-transparent bg-primary-dark hover:text-primary-dark-hover hover:bg-accent-dark-hover focus:text-primary-dark-hover focus:bg-accent-dark-hover focus:ring-accent-dark-hover',
  submit: 'bg-orange text-light border border-transparent hover:bg-orange-hover focus:ring-orange-hover',
  link: 'underline text-primary-dark font-semibold focus:outline-none hover:text-primary-dark-hover focus:text-primary-dark-hover hover:no-underline focus:no-underline',
  custom: 'border border-transparent',
  outline: 'border border-primary-dark bg-transparent text-primary-dark hover:text-light hover:bg-primary-dark-hover hover:border-primary-dark-hover focus:ring-primary-dark-hover',
  outlineNeutral: 'border border-primary-dark bg-accent-neutral text-primary-dark hover:text-light hover:bg-primary-dark-hover hover:border-primary-dark-hover focus:ring-primary-dark-hover',
  loading: 'h-4 w-4 ms-2 rounded-full border-2 border-transparent border-t-2 animate-spin',
  disabled: 'border border-border-base bg-gray-300 border-border-400 text-body cursor-not-allowed',
  disabledOutline: 'border border-border-base text-muted cursor-not-allowed',
  tiny: 'px-5 py-0 h-6 text-xs',
  small: 'px-5 py-0 h-9 text-sm',
  menu: 'px-5 py-0 h-10',
  medium: 'px-5 py-0 h-12',
  big: 'px-6 py-0 h-15',
};

const classesName = computed(() => {
    return [
        classes.root,
        !props.disabled && props.variant === 'navbar' ? classes.navbar : '',
        !props.disabled && props.variant === 'accent' ? classes.accent : '',
        !props.disabled && props.variant === 'normal' ? classes.normal : '',
        !props.disabled && props.variant === 'submit' ? classes.submit : '',
        !props.disabled && props.variant === 'link' ? classes.link : '',
        props.disabled && props.variant === 'navbar' ? classes.disabled : '',
        props.disabled && props.variant === 'accent' ? classes.disabled : '',
        props.disabled && props.variant === 'normal' ? classes.disabled : '',
        props.disabled && props.variant === 'submit' ? classes.disabled : '',
        props.disabled && props.variant === 'link' ? classes.disabled : '',
        !props.disabled && props.variant === 'outline' ? classes.outline : '',
        props.disabled && props.variant === 'outline' ? classes.disabledOutline : '',
        !props.disabled && props.variant === 'outlineNeutral' ? classes.outlineNeutral : '',
        props.disabled && props.variant === 'outlineNeutral' ? classes.disabledOutline : '',
        props.size === 'tiny' ? classes.tiny : '',
        props.size === 'small' ? classes.small : '',
        props.size === 'menu' ? classes.menu : '',
        props.size === 'medium' ? classes.medium : '',
        props.size === 'big' ? classes.big : '',
        props.className
    ];
});

</script>
