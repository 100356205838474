<template>
  <FilterTag :link="translatePath(`${data?.product?.type === PRODUCT_TYPE.OFFER ? ROUTES.OFFERS : ROUTES.REQUESTS}/${slugify(data?.product?.category?.id, data?.product?.category?.name)}`)" :on-click="handleClick">
    {{ data?.product?.category?.name }}
  </FilterTag>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { PRODUCT_TYPE } from "@utils/constants";
import FilterTag from "@components/filter/filter-tag";

const props = defineProps({
  data: {
    type: Object,
    required: true 
  }
});

const { $eventBus } = useNuxtApp();
const route = useRoute();
const router = useRouter();

const routeName = computed(() => route?.name || '');

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const handleClick = () => {
  const params = { ...route.query };
  _.forEach(_.keys(params), key => {
    if (/^p\[\d+\]$/.test(key)) {
      delete params[key];
    }
  });
  closeModal();
  router.push({
    path: routeName.value.startsWith('classified-ads___') || 
      routeName.value.startsWith('favorites___') || 
      routeName.value.startsWith('offers___') || 
      routeName.value.startsWith('requests___') ? route.path : translatePath(ROUTES.ADS),
    query: params,
  });
  useSearchParam('idCategory', `${props.data?.product?.category?.id || ''}`);
}

</script>
