import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected';

export default async function (id, quantity) {
  const store = useSelectedStore();

  if (store.action === 'ADD_TO_CART' && store.product?.id) {
    await cartAddItemToCart(store.product, 1);
    return true;
  } else if (store.action === 'ADD_NEW_AD') {
    await navigateTo(translatePath(ROUTES.AD_ADD));
    return true;
  } else if (store.action === 'ADD_NEW_INSPIRATION') {
    await navigateTo(translatePath(ROUTES.INSPIRATION_ADD));
    return true;
  } else if (store.action === 'REDIRECT_TO_HOME') {
    store.setAction(null);
    await navigateTo(translatePath(ROUTES.HOME));
    return true;
  }

  return false;
}
