<template>
    <div v-if="loading" class="w-96 flex justify-center items-center h-96 bg-accent-neutral relative">
        <Spinner :text="$t('common:text-loading')" />
    </div>
    <ContentUnavailable v-else-if="!variant?.id" class="p-6 lg:p-14 xl:p-16 bg-light rounded-panel" />
    <article v-else class="bg-accent-neutral w-full max-w-6xl relative z-[51]">
        <ContentCategoryBreadcrumbs 
            v-if="variant?.category" 
            :type="store.content?.type"
            :categories="[ variant?.category ]"
            :on-close="closeModal"   
        />

        <Scrollbar class="w-full max-h-dmv mt-5" :options="{ scrollbars: { autoHide: 'never' } }">
            <div class="flex flex-col md:flex-row">
                <ContentDetailGallery :variant="variant" class="mb-9" />
                <div class="w-full md:w-1/2 px-5 md:px-3 py-0 md:py-5">
                    <ContentDetailInfo :variant="variant" class="flex flex-col items-start overflow-hidden" size="modal" @select:tab:comments="selectTabComments" />
                </div>
            </div>
            <div class="w-full flex items-center justify-center py-8">
              <Button size="big" :on-click="closeModal">
                <span class="text-sm px-9">{{ $t('text-close') }}</span>
              </Button>
            </div>
        </Scrollbar>
    </article>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import Spinner from "@components/ui/loaders/spinner/spinner";
import Button from "@components/ui/button";
import ContentUnavailable from "@components/content/content-details/content-unavailable";
import ContentDetailGallery from "@components/content/content-details/content-detail-gallery";
import ContentDetailInfo from "@components/content/content-details/content-detail-info";
import ContentCategoryBreadcrumbs from "@components/category/content-category-breadcrumbs";
import Scrollbar from "@components/ui/scrollbar";

const loading = ref(true);
const router = useRouter();
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const auth = useAuthStore();
const store = useSelectedStore();
const routeLang = useRouteLang();

const variant = ref({});
const setVariant = (data) => {
    _.assignIn(variant.value, data);
}

const reload = async () => {
    loading.value = true;
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.CONTENT_POST_ID, {
      method: 'GET',
      path: {
        id: store.content.id
      },
      params: {
        locale: routeLang.locale.value
      }
    });
    if (status.value === 'error') {
      if (process.client && !auth.reloadTimeout) {
        $toast.error(t('error.content-detail.load'));
      }
      sentryCaptureEvent({
        message: 'content-details-modal-view.reload',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      setVariant(data.value);
    }
    loading.value = false;
}

onServerPrefetch(async () => await reload());

const contentUpdate = (value) => {
  if (value.id === variant.value.id) {
    variant.value = { ...variant.value, ...value };
  }
};

onMounted(async () => {
    await reload();
    $eventBus.on('content:update', contentUpdate);
    if (store.action === 'SELECT_TAB_COMMENTS') {
        $eventBus.emit('focus:input', 'comment');
    }
});

onUnmounted(() => {
  $eventBus.off('content:update', contentUpdate);
});

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const selectTabComments = (id) => {
    if (id === variant.value.id) {
        $eventBus.emit('focus:input', 'comment');
    }
}

</script>
