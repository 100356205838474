import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { defineStore } from 'pinia'
import { CUSTOMER_KEY } from "@utils/constants";
import { useAuthStore } from '@stores/auth';

export const useCustomerStore = defineStore(CUSTOMER_KEY, () => {

  const { $eventBus } = useNuxtApp();
  const auth = useAuthStore();
  const config = useRuntimeConfig();
  const routeLang = useRouteLang();

  const loading = useState('customer.loading', () => false);
  const id = useState('customer.id', () => 0);
  const firstName = useState('customer.firstName', () => '');
  const lastName = useState('customer.lastName', () => '');
  const email = useState('customer.email', () => '');
  const city = useState('customer.city', () => '');
  const status = useState('customer.status', () => '');
  const insertedAt = useState('customer.insertedAt', () => '');

  const fullName = computed(() => `${firstName.value} ${lastName.value}`.replace(/\s{2,}/g, ' ').trim());

  const detail = useState('customer.detail', () => {});
  const setData = (data) => {
    if (data?.id) {
      detail.value = data;
      id.value = _.get(data, 'id', id.value);
      firstName.value = _.get(data, 'firstName', firstName.value);
      lastName.value = _.get(data, 'lastName', lastName.value);
      email.value = _.get(data, 'email', email.value);
      city.value = _.get(data, 'city', city.value);
      status.value = _.get(data, 'status', status.value);
      insertedAt.value = _.get(data, 'insertedAt', insertedAt.value);
    }
  }

  const load = async () => {
    if (!auth.isLoggedIn) {
      reset();
    }

    if (loading.value || !auth.isLoggedIn || !auth.idCustomer || id.value === auth.idCustomer) {
      return;
    }

    if (auth.isLoggedIn) {
      await loadCustomer();
      await loadVisitor();
      await loadSupplier();
    } else {
      await visitorUpdate(routeLang.code.value);
    }

    if (!auth.isLoggedIn) {
      reset();
    }

    await checkLanguage();
  }

  const reset = () => {
    detail.value = {};
    id.value = 0;
    firstName.value = '';
    lastName.value = '';
    email.value = '';
    city.value = '';
    status.value = '';
    insertedAt.value = '';
    supplier.value = {};
    logo.value = '';
  }

  const logo = useState('customer.logo', () => '');
  const supplier = useState('customer.supplier', () => {});
  const setSupplier = (data) => {
    if (data?.id) {
      supplier.value = data;
      logo.value = data?.logo;
      $eventBus.emit('supplier:update', data);
    }
  }

  const hasHomeLocation = () => {
    return auth.isLoggedIn && auth.idSupplier && (
      _.get(supplier.value, 'billingAddress.latitude', false) ||
      _.get(supplier.value, 'billingAddress.longitude', false) ||
      _.get(supplier.value, 'deliveryAddress.latitude', false) ||
      _.get(supplier.value, 'deliveryAddress.longitude', false)
    );
  }

  const language = useState('customer.language', () => ({
    code: routeLang.code.value,
    locale: routeLang.locale.value
  }));
  const setLanguage = (data) => {
    if (data?.id) {
      language.value = data;
    }
  }

  const currency = useState('customer.currency', () => {});
  const setCurrency = (data) => {
    if (data?.id) {
      currency.value = data;
    }
  }

  const hasDeliveryAddress = () => {
    return auth.isLoggedIn && auth.idSupplier &&
      _.get(supplier.value, 'deliveryAddress.street', false) &&
      _.get(supplier.value, 'deliveryAddress.streetNo', false) &&
      _.get(supplier.value, 'deliveryAddress.city', false) &&
      _.get(supplier.value, 'deliveryAddress.zip', false) &&
      _.get(supplier.value, 'deliveryAddress.idCountry', false) &&
      _.get(supplier.value, 'deliveryAddress.phone', false);
  }

  const hasBillingAddress = () => {
    return auth.isLoggedIn && auth.idSupplier &&
      _.get(supplier.value, 'billingAddress.street', false) &&
      _.get(supplier.value, 'billingAddress.streetNo', false) &&
      _.get(supplier.value, 'billingAddress.city', false) &&
      _.get(supplier.value, 'billingAddress.zip', false) &&
      _.get(supplier.value, 'billingAddress.idCountry', false) &&
      _.get(supplier.value, 'billingAddress.phone', false);
  }

  return {
    fullName, detail, loading, load, setData, reset,
    logo, supplier, setSupplier, hasHomeLocation,
    language, setLanguage, currency, setCurrency,
    id, firstName, lastName, email, city, status, insertedAt,
    hasDeliveryAddress, hasBillingAddress
  }
})
