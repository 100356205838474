<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
    <h2 class="flex font-semibold text-lg md:text-xl text-heading mb-2">
      {{ $t('text-supplier-image-edit') }}
    </h2>
    <hr class="mb-2 md:mb-4" />
    <div class="flex flex-col sm:flex-row justify-between w-full">
      <TextArea
        :label="$t('text-description')"
        v-model="description"
        name="description"
        type="text"
        variant="outline"
        class-name="w-full mb-5"
        :error="errors?.description?.message ? $t(errors.description.message) : ''"
      />
    </div>
    <div class="flex flex-col sm:flex-row justify-end w-full">
      <Button 
          class="ms-auto" 
          :disabled="loading"
          :loading="loading"
          :on-click="handleSubmit"
      >
        {{ $t('text-save') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { siteSettings } from "@settings/site.settings";
import TextArea from "@components/ui/text-area";
import Button from "@components/ui/button";

const store = useSelectedStore();
const customer = useCustomerStore();
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const errors = ref({});
const loading = ref(false);
const description = ref(_.get(store.image, 'description', ''));

const handleSubmit = async () => {
  loading.value = true;
  const result = await supplierImageUpdate(store.image?.id, { 
    isMain: store.image?.isMain || false,
    languages: [
      {
        idLanguage: customer.language?.id || siteSettings.language.id, //@TODO
        description: description.value
      }
    ] 
  });
  if (result) {
    store.image.description = _.get(result, 'languages.0.description');
    $toast.success(t('success.supplier.image.update'));
    $eventBus.emit('modal:close');
  } else {
    $toast.error(t('error.supplier.image.update'));
  }
  loading.value = false;
}

</script>
