import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useEnumStore } from '@stores/enum';

export default async function (force = false) {
  const store = useEnumStore();
  const routeLang = useRouteLang();

  if (!store?.banners?.length || force) {
    store.setLoading(true);
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.BANNER, {
      params: {
        locale: routeLang.locale.value
      }
    });

    if (status.value === 'error') {
      sentryCaptureEvent({
        message: 'load-banners',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      store.setBanners(data.value);
    }
    store.setLoading(false);
  }
}
