<template>
    <article class="bg-accent-neutral w-full h-screen md:h-auto max-w-6xl relative z-[51]">
        <div v-if="!messageAdded" class="flex flex-col">
            <div class="w-full px-5 sm:px-10 pt-5 sm:pt-10">
                <h1 class="font-bold text-2xl tracking-tight text-primary-dark transition-colors hover:text-primary-dark-hover mb-2">
                    {{ $t('text-recommended-my-product') }}
                </h1>
                <div class="text-base font-light">
                  {{ $t('text-recommended-my-product-subtitle-1') }}
                  <span class="underline hover:no-underline font-bold text-primary-dark hover:text-primary-dark-hover" @click="handleAdd">{{ $t('text-recommended-my-product-subtitle-2') }}</span>
                  {{ $t('text-recommended-my-product-subtitle-3') }}
                </div>
            </div>
            <div class="p-5">
                <div class="flex items-center justify-center">
                    <div class="flex w-full max-w-md relative text-sm h-10">
                      <input
                        :value="filters.fulltext"
                        :placeholder="$t('common:text-search-ad-placeholder')"
                        autoComplete="off"
                        class="w-full h-full flex item-center appearance-none transition duration-300 ease-in-out text-primary-dark text-xs placeholder:text-primary-dark font-bold overflow-hidden focus:outline-none focus:ring-0 border border-primary-dark rounded-full focus:border-primary-dark-hover hide-arrows px-5"
                        @change="handleSubmit"
                        @keyup.enter="handleSubmit"
                      />
                      <button
                        v-if="filters.fulltext"
                        type="button"
                        @click="handleClear"
                        class="cursor-pointer h-full w-10 flex items-center justify-center absolute text-primary-dark transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover end-0"
                      >
                        <span class="sr-only">{{ $t('text-close') }}</span>
                        <CloseIcon class="w-3.5 h-3.5 md:w-3 md:h-3" />
                      </button>
                      <button
                        v-else
                        @click="handleSubmit"
                        class="cursor-pointer h-full w-10 flex items-center justify-center absolute text-primary-dark transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover end-0"
                      >
                        <SearchIcon class="w-4 h-4" />
                      </button>
                    </div>
                </div>
            </div>
            <Scrollbar class="w-full max-h-rpmv px-5" :options="{ scrollbars: { autoHide: 'never' } }">
                <div v-if="!loading && pages.length && !_.get(pages, '0.items', []).length" class="flex items-center justify-center">
                    <div class="w-full md:w-156 2xl:w-239 flex flex-col justify-center ml-1">
                        <NotFound text="text-no-filtered-found" class="max-w-lg mx-auto" />
                    </div>
                </div>
                <div v-else>
                    <div v-if="loading || !isMounted" class="w-full flex justify-center">
                      <div class="w-full sm:w-fit gap-x-9 gap-y-2.5 sm:gap-y-9 grid md:grid-cols-2 2xl:grid-cols-3">
                        <ProductFeedLoader :limit="isMounted ? gridWidth * 2 : 2" />
                      </div>
                    </div>
                    <div v-else class="w-full flex justify-center">
                      <div class="w-full sm:w-fit gap-x-9 gap-y-2.5 sm:gap-y-9 grid md:grid-cols-2 2xl:grid-cols-3">
                        <RenderProductCard 
                            v-for="(variant, idx) in variants" 
                            :key="variant.id"
                            :variant="variant"
                            action="recommend"
                        />
                      </div>
                    </div>
                </div>
                <div v-if="hasNextPage || loadingMore" class="flex justify-center mt-8 lg:mt-12">
                  <Button
                      :loading="loadingMore"
                      @click="handleLoadMore"
                      class-name="text-sm md:text-base font-semibold h-11"
                  >
                      {{ $t("text-load-more") }}
                  </Button>
                </div>
                <div class="w-full flex items-center justify-center py-8">
                  <Button size="big" :on-click="handleClose">
                    <span class="text-sm px-9">{{ $t('text-close') }}</span>
                  </Button>
                </div>
            </Scrollbar>
        </div>
        <div v-else>
            <Scrollbar class="w-full max-h-screen" :options="{ scrollbars: { autoHide: 'never' } }">
                <div class="w-full flex flex-col items-center justify-center px-5 sm:px-10 pt-5 sm:pt-10">
                    <h1 class="font-bold text-2xl tracking-tight text-primary-dark transition-colors hover:text-primary-dark-hover mb-5">
                        {{ $t('text-recommended-my-product-result-1') }}
                    </h1>
                    <div @click="closeModal">
                        <RenderProductCard :variant="store.product" action="view" />
                    </div>
                    <div class="font-normal text-base text-primary-dark transition-colors hover:text-primary-dark-hover mt-5">
                        {{ $t('text-recommended-my-product-result-2') }}
                    </div>
                    <div 
                      v-for="(chunk, idx) in markdown(messageAdded.content)"
                      :key="idx"
                      class="mb-5"
                    >
                      <Link
                        v-if="chunk.type === 'link'"
                        :href="chunk.href"
                        class="font-bold text-lg tracking-tight text-primary-dark cursor-pointer transition-colors hover:text-primary-dark-hover underline hover:no-underline"
                        @click="closeModal"
                      >
                        {{ chunk.content }}
                      </Link>
                    </div>
                    <Button size="big" class="mb-5" :on-click="handleShowMessage">
                      <span class="text-sm px-9">{{ $t('text-show-message') }}</span>
                    </Button>
                    <Button size="big" class="mb-5" :on-click="handleClose">
                      <span class="text-sm px-9">{{ $t('text-close') }}</span>
                    </Button>
                </div>
            </Scrollbar>
        </div>
    </article>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { ROUTES } from "@utils/routes";
import { PRODUCT_TYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { siteSettings } from "@settings/site.settings";
import Button from "@components/ui/button";
import ProductFeedLoader from "@components/ui/loaders/product-feed-loader";
import RenderProductCard from "@components/product/render-product-card";
import SearchIcon from '@components/icons/search-icon'
import CloseIcon from "@components/icons/close-icon";
import NotFound from "@components/common/not-found";
import Scrollbar from "@components/ui/scrollbar";
import Link from "@components/ui/link/link";

const router = useRouter();
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const auth = useAuthStore();
const store = useSelectedStore();
const gridSize = useGridSize();
const route = useRoute();
const routeLang = useRouteLang();

const pages = ref([]);
const pagination = ref({ page: 1, pageCount: 1, itemsPerPage: 6, itemCount: 0 });
const loading = ref(true);
const hasNextPage = computed(() => pagination.value.page < pagination.value.pageCount);
const loadingMore = ref(false);
const isMounted = ref(false);
const messageAdded = ref(null);

const routePath = computed(() => route?.path || '');

const filters = ref({
  type: PRODUCT_TYPE.OFFER,
  idSupplier: auth.idSupplier,
  fulltext: ''
});

const variants = computed(() => loading.value && !pages.value.length 
  ? [] 
  : _.flatMap(pages.value, (page) => page?.items || [])
);

const gridWidth = computed(() => {
    return gridSize.width.value > 2 ? gridSize.width.value - 1 : gridSize.width.value;
});

const reload = async (pending = loading) => {
  pending.value = true;
  
  const body = _.pickBy(filters.value, value => value !== null && value !== '' && value !== 0);

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_PRODUCT_VARIANT, {
    method: 'POST',
      params: {
        page: pagination.value.page,
        limit: pagination.value.itemsPerPage,
        sort: filters.value?.sort || 'publishedAt',
        direction: filters.value?.direction || 'desc',
        locale: routeLang.locale.value
      },
      body
  });
  if (status.value === 'error') {
      if (process.client) {
        $toast.error(t('error.product-list.load'));
      }
      sentryCaptureEvent({
        message: 'recommended-product-modal-view.reload',
        level: 'error',
        extra: { error: error.value }
      });
  } else {
    pages.value.push(data.value);
    pagination.value = data.value.pagination;
  }
  pending.value = false;
}

const handleLoadMore = async () => {
  pagination.value.page++;
  await reload(loadingMore);
}

const messageAdd = (data) => {
  if (data.id === store.product.id && data.type === 'variant') {
    messageAdded.value = data?.message || {};
  }
};

onMounted(async () => {
  isMounted.value = true;
  await reload();
  $eventBus.on('message:add', messageAdd);
});

onUnmounted(() => {
  $eventBus.off('message:add', messageAdd);
});

const reset = () => {
  pages.value = [];
  pagination.value.page = 1;
}

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const handleAdd = async () => { 
  closeModal();
  if (auth.isLoggedIn) {
    await navigateTo(translatePath(ROUTES.AD_ADD));
  } else {
    store.setAction('ADD_NEW_AD');
    $eventBus.emit('modal:open', 'LOGIN_VIEW');
  }
};

const handleClose = () => {
  closeModal();
  if (store.action === 'RETURN_TO_PRODUCT_MODAL') {
    $eventBus.emit('modal:open', 'PRODUCT_DETAILS');
    store.setAction(null);
  }
};

const handleSubmit = async (event) => {
  if (!loading.value) {
    filters.value.fulltext = event.target.value;
    reset();
    await reload();
  }
};

const handleClear = async (event) => {
  if (!loading.value) {
    filters.value.fulltext = '';
    reset();
    await reload();
  }
}

const handleShowMessage = async () => {
  closeModal();
  store.setAction(null);
  const link = translatePath(`${ROUTES.PRODUCT}/${slugify(store.product.id, store.product.name)}`);
  if (!routePath.value.startsWith(link)) {
    await navigateTo(`${link}#m-${messageAdded.value.id}`);
  }
}

</script>
