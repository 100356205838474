<template>
  <SidebarWrapper>
    <ul class="flex-grow">
      <li v-for="{ href, label, icon } in siteSettings.mobileMenuLinks" :key="`${href}${label}`">
        <span
          @click="() => handleClick(href)"
          :class="[ 'flex items-center py-3 px-5 md:px-8 text-sm font-semibold transition duration-200 hover:text-accent', route.path === translatePath(href) ? 'text-accent' : 'text-body-dark' ]"
        >
          <span v-if="icon" class="me-2">{{ icon }}</span>
          {{ $t(label) }}
        </span>
      </li>
      <li class="py-3 px-5 md:px-8">
        <AddInspirationButton v-if="routeName.startsWith('inspiration___') || routeName.startsWith('inspiration-type-slug___')" />
        <AddAdButton v-else />
      </li>
    </ul>
  </SidebarWrapper>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { useAuthStore } from '@stores/auth';
import { siteSettings } from "@settings/site.settings";
import SidebarWrapper from "@components/common/sidebar/sidebar-wrapper";
import AddAdButton from "@components/layout/navbar/add-ad-button";
import AddInspirationButton from "@components/content/add-inspiration-button";

const auth = useAuthStore();
const route = useRoute();
const router = useRouter();
const { $eventBus } = useNuxtApp();

const routeName = computed(() => route?.name || '');

const closeSidebar = () => {
    $eventBus.emit('sidebar:close');
}

const handleClick = async (href) => {
    closeSidebar();
    await navigateTo(translatePath(href));
}

</script>
