import * as Sentry from "@sentry/browser";
import _ from 'lodash-es';
import UAParser from 'ua-parser-js';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { getDeviceInfo } from "@utils/get-device-info";
import { defineStore } from 'pinia'
import { AUTH_KEY } from "@utils/constants";
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected'
import { useCustomerStore } from '@stores/customer';

export const useAuthStore = defineStore(AUTH_KEY, () => {

  const { $eventBus } = useNuxtApp();
  const { $dayjs, ssrContext } = useNuxtApp();
  const cart = useCartStore();
  const store = useSelectedStore();
  const customer = useCustomerStore();

  const loaded = useState('auth.loaded', () => false);
  const setLoaded = (value) => {
    loaded.value = value;
  }

  const sid = useState('auth.sid', () => null);
  const setSid = (value) => {
    sid.value = value;
  }

  const expiresAt = useState('auth.expiresAt', () => null);
  const setExpiresAt = (value) => {
    expiresAt.value = value;
  }

  const isLoggedIn = useState('auth.isLoggedIn', () => false);
  const setIsLoggedIn = (value) => {
    isLoggedIn.value = value;
  }

  const idCustomer = useState('auth.idCustomer', () => 0);
  const setIdCustomer = (value) => {
    idCustomer.value = value;
  }

  const idSupplier = useState('auth.idSupplier', () => 0);
  const setIdSupplier = (value) => {
    idSupplier.value = value;
  }

  const locale = useState('auth.locale', () => 'cs');
  const setLocale = (value) => {
    locale.value = value;
  }

  const plan = useState('auth.plan', () => '');
  const setPlan = (value) => {
    plan.value = value;
  }

  const role = useState('auth.role', () => {});
  const setRole = (value) => {
    role.value = value;
  }

  const fcmToken = useState('auth.fcmToken', () => '');
  const setFcmToken = (value) => {
    fcmToken.value = value;
  }

  const notificationInfo = useState('auth.notificationInfo', () => {});
  const setNotificationInfo = (value) => {
    notificationInfo.value = value;
  }

  const isSessionActive = () => {
    return sid.value && expiresAt.value && $dayjs().isBefore($dayjs(expiresAt.value));
  }

  const reset = () => {
    sid.value = null;
    expiresAt.value = null;
    isLoggedIn.value = false;
    idCustomer.value = 0;
    idSupplier.value = 0;
    plan.value = '';
    role.value = {};
    fcmToken.value = '';
    notificationInfo.value = {};
  }

  const getData = () => ({
    sid: sid.value,
    expiresAt: expiresAt.value,
    isLoggedIn: isLoggedIn.value,
    idCustomer: idCustomer.value,
    idSupplier: idSupplier.value,
    plan: plan.value,
    role: Object.assign({
      code: "visitor",
      name: "Visitor",
      rights: [
        "country.get",
        "currency.get",
        "language.get",
        "sign.post",
        "heartbeat.post",
        "visitor.get",
        "visitor.put",
        "customer.post",
        "customer/forgot-password.post",
        "customer/forgot-password.put",
        "supplier.get",
        "eshop/category.get",
        "eshop/parameter.get",
        "eshop/product/variant.get",
        "eshop/product/variant.post",
        "eshop/product/variant/message.get",
        "eshop/availability.get"
      ]
    }, role.value)
  })

  const setData = (data) => {
    if (data && data.sid) {
      me.value = data;
      sid.value = _.get(data, 'sid', sid.value);
      expiresAt.value = _.get(data, 'expiresAt', expiresAt.value);
      isLoggedIn.value = _.get(data, 'isLoggedIn', isLoggedIn.value);
      idCustomer.value = _.get(data, 'idCustomer', idCustomer.value);
      idSupplier.value = _.get(data, 'idSupplier', idSupplier.value);
      locale.value = _.get(data, 'locale', locale.value);
      plan.value = _.get(data, 'plan', plan.value);
      role.value = _.get(data, 'role', role.value);
      notificationInfo.value = _.get(data, 'notificationInfo', notificationInfo.value);
      loaded.value = true;

      const scope = Sentry.getCurrentScope();
      scope.setUser(_.pick(data, ['sid', 'expiresAt', 'isLoggedIn', 'idCustomer', 'idSupplier']));
    }
  }

  const me = useState('auth.me', () => getData());

  let apiAuth = {};
  if (process.server) {
    apiAuth = ssrContext.event.context.apiAuth;
    if (typeof apiAuth.isLoggedIn !== 'undefined') {
      setData(apiAuth);
      loaded.value = true;
    }
  }

  const reloading = ref(false);
  const reloadTimeout = ref(0);
  const reloadCountDown = ref(0);
  const reloadSession = async () => {
    try {
      if (reloadCountDown.value) {
        reloadCountDown.value--;
        return ;
      }
      if ((!isSessionActive() || (useCookie('auth_sid').value !== sid.value)) && !reloading.value) {
        loaded.value = false;
        reloading.value = true;
        const res = await loadMe();
        if (res === null || res?.sid === null) {
          reloadTimeout.value++;
          reloadCountDown.value = reloadTimeout.value;
          reloading.value = false;
          return ;
        } else {
          reloadTimeout.value = 0;
        }
        if (!isLoggedIn.value || !idCustomer.value) {
          customer.reset();
          cart.reset();
        } else {
          await customer.load();
          await cartLoad();
        }
        store.setLoadResetAll();
        reloading.value = false;
      }
    } catch (error) {
      sentryCaptureEvent({
        message: 'auth.reloadSession',
        level: 'error',
        extra: { error: error }
      });
    }
  }

  const reloadInterval = useState('auth.reloadInterval', () => null);
  if (process.client && !reloadInterval.value) {
    reloadInterval.value = setInterval(reloadSession, 1000);
  }

  return { 
    me, isSessionActive,
    sid, setSid, 
    expiresAt, setExpiresAt,
    isLoggedIn, setIsLoggedIn,
    idCustomer, setIdCustomer,
    idSupplier, setIdSupplier,
    locale, setLocale,
    plan, setPlan,
    role, setRole,
    fcmToken, setFcmToken,
    notificationInfo, setNotificationInfo,
    reset, setData, getData, 
    loaded, setLoaded, 
    reloading, reloadTimeout
  }

})
