<template>
    <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
      <div class="mt-5">
        <TextArea
           :label="$t('text-abandonment-reason')"
          :placeholder="$t('placeholder-abandonment-note')"
          v-model="abandonmentNote"
          name="abandonmentNote"
          type="abandonmentNote"
          variant="outline"
          class-name="mb-5"
        />
        <div class="mt-8">
          <Button
            class="w-full h-11 sm:h-12"
            :loading="loading"
            :disabled="loading"
            :on-click="handleSubmit"
          >
            {{ $t('text-cancel-order') }}
          </Button>
        </div>
      </div>
      <Alert
        v-if="errorMsg"
        variant="error"
        :message="errorMsg"
        class="mt-6"
        :closeable="true"
        :on-close="() => errorMsg = ''"
      />
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useEnumStore } from '@stores/enum';
import { useSelectedStore } from '@stores/selected';
import Combobox from "@components/ui/combobox";
import TextArea from "@components/ui/text-area";
import Button from "@components/ui/button";
import Alert from "@components/ui/alert";

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const errors = ref({});
const errorMsg = ref('');

const loading = ref(false);
const abandonmentReason = ref(null);
const abandonmentNote = ref('');
const enums = useEnumStore();
const store = useSelectedStore();

const handleSubmit = async () => {
  loading.value = true;
  const data = await cartCancelOrder(store.cart?.id, {
    idAbandonmentReason: abandonmentReason.value,
    abandonmentNote: abandonmentNote.value
  });
  if (data.status === 'closed') {
    $toast.success(t('success.cart.cancel.order'));
    await cartLoad();
    $eventBus.emit('modal:close');
    await navigateTo(translatePath(`${ROUTES.HOME}`));
  } else {
    $toast.error(t('error.cart.cancel.order'));
  }
  loading.value = true;
}

onMounted(async () => {
  // await loadAbandonmentReasons();
});

</script>
