<template>
  <div class="h-15 bg-primary-dark radial-gradient rounded-b-panel text-light flex items-center justify-between">
    <ul class="flex flex-nowrap items-center list-none px-5 lg:px-10 py-2">
      <li class="pl-3 hidden lg:flex items-center space-x-3">
        <Link 
          :href="translatePath(rootPath)"
          class="tracking-wider whitespace-nowrap text-sm lg:text-base hover:underline"
          @click="onClose"
        >
          {{ $t(title) }}
        </Link>
        <ChevronRight class="w-3 h-3" />
      </li>
      <li 
        v-for="(category, idx) in breadcrumbs"
        :key="category.id"
        class="flex flex-row flex-nowrap items-center"
      >
        <ChevronRight v-if="idx > 0" class="w-3 h-3" />
        <Link
          :href="translatePath(`${basePath}/${slugify(category?.id, category?.name)}`)"
          class="tracking-wider whitespace-nowrap text-sm lg:text-base hover:underline px-3"
          @click="onClose"
        >
          {{ category.name }}
        </Link>
      </li>
    </ul>
    <div class="mt-2">
      <slot />
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useCategoryStore } from '@stores/category';
import Link from "@components/ui/link/link";
import ChevronRight from "@components/icons/outline/chevron-right";

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  categories: {
    type: Array,
    default: () => []
  },
  onClose: {
    type: Function,
    default: () => { }
  }
})

const categories = useCategoryStore();
const breadcrumbs = ref(props.categories);

const title = computed(() => {
  return {
    inspiration: 'text-all-inspirations',
    news: 'text-all-news'
  }[props.type] || 'text-all-ads';
});

const rootPath = computed(() => {
  return {
    inspiration: ROUTES.INSPIRATION,
    news: ROUTES.NEWS
  }[props.type] || ROUTES.PRODUCT;
});

const basePath = computed(() => {
  return {
    inspiration: ROUTES.INSPIRATION_TYPE,
    news: ROUTES.NEWS_ABOUT
  }[props.type] || ROUTES.CATEGORY;
});

const categoryPath = (childCategoryId, currentCategories = categories.content || [], path = []) => {
  for (const category of currentCategories) {
    if (category.id === childCategoryId) {
      return [...path, category];
    }
    const newPath = categoryPath(childCategoryId, category.categories, [...path, category]);
    if (newPath) return newPath;
  }
  
  return undefined;
}

const updateBreadcrumbs = (value) => {
  breadcrumbs.value = categoryPath(_.first(props.categories)?.id, value || categories.content || []) || props.categories;
}

watch(() => categories.eshop, (value) => {
  updateBreadcrumbs(value)  
});

onServerPrefetch(() => {
  updateBreadcrumbs();
});

onMounted(() => {
  updateBreadcrumbs();
});


</script>

<style scoped>
.radial-gradient {
  background-image: radial-gradient(ellipse at 30% 50%, #4B7F3D, #0D2712 70%);
}
</style>
