import revive_payload_client_4sVQNw7RlN from "/home/aquarist/www/prod/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/aquarist/www/prod/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/aquarist/www/prod/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/aquarist/www/prod/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/aquarist/www/prod/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/aquarist/www/prod/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/aquarist/www/prod/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_TvWhUGmZxm from "/home/aquarist/www/prod/node_modules/nuxt-open-fetch/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/home/aquarist/www/prod/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "/home/aquarist/www/prod/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/aquarist/www/prod/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/aquarist/www/prod/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_handler_VFH3VvK7yG from "/home/aquarist/www/prod/plugins/error-handler.js";
import eventBus_cMx6qAgliS from "/home/aquarist/www/prod/plugins/eventBus.js";
import firebase_client_nS52P0yitD from "/home/aquarist/www/prod/plugins/firebase.client.js";
import google_maps_client_C3Ml2mzhjC from "/home/aquarist/www/prod/plugins/google-maps.client.js";
import google_recaptcha_rYK9Kziv68 from "/home/aquarist/www/prod/plugins/google-recaptcha.ts";
import luxon_s40zPPcmn5 from "/home/aquarist/www/prod/plugins/luxon.js";
import navigation_tracker_4JJrJzbuys from "/home/aquarist/www/prod/plugins/navigation-tracker.js";
import route_checker_client_RVNVVW3PyS from "/home/aquarist/www/prod/plugins/route-checker.client.js";
import sentry_client_shVUlIjFLk from "/home/aquarist/www/prod/plugins/sentry.client.ts";
import vue3_toastify_OGYNDsiW9E from "/home/aquarist/www/prod/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_TvWhUGmZxm,
  plugin_8SbxDRbG6Y,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  error_handler_VFH3VvK7yG,
  eventBus_cMx6qAgliS,
  firebase_client_nS52P0yitD,
  google_maps_client_C3Ml2mzhjC,
  google_recaptcha_rYK9Kziv68,
  luxon_s40zPPcmn5,
  navigation_tracker_4JJrJzbuys,
  route_checker_client_RVNVVW3PyS,
  sentry_client_shVUlIjFLk,
  vue3_toastify_OGYNDsiW9E
]