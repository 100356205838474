<template>
  <li v-if="info.href === ROUTES.INSPIRATION && auth.idSupplier">
    <button
      :class="[ 'flex items-center w-full pb-4 text-start outline-none font-bold focus:outline-none focus:ring-0 focus:text-primary-dark-accent hover:no-underline', idSupplier ? 'text-primary-light' : 'text-primary-dark', className ? className : 'text-base' ]"
       @click="onClick"
    >
      <span>{{ $t(info.label) }}</span>
    </button>
  </li>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { useUIStore } from '@stores/ui';
import { useAuthStore } from '@stores/auth';
import UserIcon from "@components/icons/user-icon";

const props = defineProps({
  className: {
    type: String,
    default: () => ''
  }
});

const ui = useUIStore();
const { $eventBus } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const auth = useAuthStore();

const isOpen = ref(false);
const idSupplier = computed(() => +route.query.idSupplier);

const routeName = computed(() => route?.name || '');

const info = computed(() => {
  if (routeName.value.startsWith('offers___') || routeName.value.startsWith('offers-slug___')) {
    return { label: 'text-my-offers', href: ROUTES.OFFERS };
  } else if (routeName.value.startsWith('requests___') || routeName.value.startsWith('requests-slug___')) {
    return { label: 'text-my-requests', href: ROUTES.REQUESTS };
  } else if (routeName.value.startsWith('inspiration___') || routeName.value.startsWith('inspiration-type-slug___')) {
    return { label: 'text-my-inspiration', href: ROUTES.INSPIRATION };
  } else if (routeName.value.startsWith('favorites___')) {
    return { label: 'text-my-favorites', href: ROUTES.FAVORITES };
  } else {
    return { label: 'text-my-ads', href: ROUTES.ADS };
  }
});

const closeSidebar = () => { 
    $eventBus.emit('sidebar:close');
};

const onClick = () => {
  const params = { ...route.query };
  if (!params.idSupplier) params.idSupplier = auth.idSupplier; else delete params.idSupplier;
  router.push({
    path: translatePath(info.value.href),
    query: params,
  })
  if (ui.displaySidebar) {
    closeSidebar();
  }
}

</script>
