export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const routeLang = useRouteLang();

  return new Promise((resolve) => {
    if (typeof window !== 'undefined' && window.google) {
      resolve(window.google);
      return;
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.public.placesApiKey}&libraries=places,marker&language=${routeLang.code.value}`;
    script.async = true;
    script.onload = () => resolve(window.google);
    document.head.appendChild(script);
  });  
});
